.app {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: radial-gradient(circle at center, #000022, #000000);
  -webkit-tap-highlight-color: transparent;
  touch-action: none;
  will-change: transform;
  width: 100vw;
  height: 100vh;
  -webkit-overflow-scrolling: none;
  overscroll-behavior: none;
  user-select: none;
}

canvas {
  display: block;
  width: 100%;
  height: 100%;
  touch-action: none;
  will-change: transform;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  width: 100%;
  height: 100%;
  overscroll-behavior-y: none;
  touch-action: none;
} 